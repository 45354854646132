export default [
  '3/4 cadre',
  'Plaque de couverture',
  "Trous d'accrochage",
  'Tôle pliée',
  'Tôles pliées',
  'Grille de protection',
  'Arrêt',
  'ambout',
  'treillis d’armature profilé',
  'Supports-A',
  'Béquille',
  'Pied de balcon',
  'Ballustrade de balcon',
  'Cadre de balcon',
  'Support de balcon',
  'caisse',
  'Suppléments',
  'Liant',
  'Tôle',
  'Couvercle de tôle',
  'Tôles',
  'Caisse en tôle',
  'Profile en tôle',
  'Étrier de retenue',
  'Châssis',
  'Manchon ',
  'Coude',
  'Prolongation coudée',
  'Brossage des perçages (trous)',
  'Boulon / tige',
  'Fer beton',
  'Coulisse',
  'Brides',
  'Balustrade',
  'Étrier',
  'Châssis / ustensiles',
  'Container',
  'C-Profilés',
  'Toit',
  'Panne de toit',
  'Porte -bagages de toit',
  'Couvercle',
  'Div. petites pièces',
  'Couvercle de dôme',
  'Tonne de pression',
  "Barrière d'angle",
  'Axe routier',
  'Mâts ',
  'Mâts de route ',
  'Couvercle de fût',
  'Grille de fenêtre',
  'Cadre de fenêtre',
  'Fer plat',
  'Bride',
  'bride avec tube',
  'Porte à battants',
  'Porte de jardin',
  'Boîte',
  'Barrière',
  'Poteau de barrière',
  'Cadre de grille',
  'Pièce de barrière',
  'Châssis',
  'Nettoyage des filetages',
  'Treillis ',
  'Panier en  treillis',
  'Grille caillebotis',
  'Pièce en fonte',
  'Crochet',
  'Appuis',
  'Main courante',
  'Faux-cadre',
  'Profil Jansen',
  'Tronçon',
  'Châssis',
  'Candélabre',
  'Parcloses',
  'Petites pièces',
  'Pinces',
  'Consoles',
  'Construction',
  'Panier',
  'Griffe',
  'Boule',
  'Grille pour lampe',
  'Profil latéral',
  'Pattes',
  'Rail de roulement',
  'Échelle',
  'Brides pour échelle',
  'Glissière',
  'Boite couché',
  'Tôle pérforée',
  'Perçage de trous',
  'Tôle à larmes',
  'Poteau',
  'Console de moteur',
  'Chandeliers fouettés',
  'Graisse',
  'Poteau',
  'Pied de poteau',
  'Socle de poteau',
  'Plaque',
  'Podium',
  'Cadre de plate-forme',
  'Soutien de plate-forme',
  'Cadre de portail',
  'Lot de petites pièces',
  'Profilés',
  'Cadre en profil',
  'Tube profilé',
  'Tubulaire carré',
  'Poutre transversale',
  'Jante en tôle',
  'Cadre',
  'Tubulaire  rectangulaire',
  'Porte pneus',
  'Tôle striée',
  'Bagues',
  'Caniveau',
  'Tube',
  'Cadre en tubulaire',
  'Tuyaux de soutien',
  'Arche de rose',
  'Rouille',
  'Fer rond',
  'Charnières',
  'Porte coulissante',
  'Rail',
  'Vis',
  'Grille de protection',
  'Console',
  'Porte cable à poulies',
  'Guide d assise',
  'Socle',
  'Barraudage',
  'Barrières',
  'Équipement stable',
  'Tige',
  'Tige/Tube',
  'Support de tige',
  'Candélabre',
  'Entretoise/jambe de force',
  'Métal déployée',
  'Support',
  'Cadre de tableau',
  'Portail',
  'Cadre de porte',
  'Changement de tournée',
  'Profil en T',
  'Poutre',
  'Tôles de séparation',
  'Traverse',
  'Balustrade en traverse',
  'Arc de séparation',
  'Grille de séparation',
  'Escalier',
  'Escaliers',
  "Barrière d' escalier",
  "Main courante d' escalier",
  "Marche d' escalier",
  'Limons',
  'Marche',
  'Porte /cadre de porte',
  'Porte',
  'Porte',
  'Petite porte',
  'Cadre de porte',
  'Rondelle',
  'Profilé en U',
  'Fers carrés',
  'Tube carré',
  'Avant toit',
  "Cadre d' avant toit",
  'Grille ondulée',
  'Escalier tournant',
  "Garde-corps d' escalier tournant",
  'Équerre',
  'Cadre en cornière',
  'Profilé en Z',
  'Tirant',
];
